import { gql } from "@apollo/client";

export const createMultiRegistryFormMutation = gql`
  mutation CreateMultiRegistryForm($students: [InputStudentsFieldType]!) {
    createMultiRegistryForm(students: $students) {
      success
    }
  }
`;

export const createRegistryFormMutation = gql`
  mutation CreateRegistryForm(
    $name: String!
    $gender: Int!
    $birth: String!
    $classType: Int!
    $applicator: ApplicatorInputType
    $textFields: [InputTextFieldType]
    $booleanFields: [InputBooleanFieldType]
    $dateFields: [InputDateFieldType]
  ) {
    createRegistryForm(
      name: $name
      gender: $gender
      birth: $birth
      classType: $classType
      applicator: $applicator
      textFields: $textFields
      booleanFields: $booleanFields
      dateFields: $dateFields
    ) {
      _id
    }
  }
`;

export const deleteRegistryFormMutation = gql`
  mutation deleteRegistryForm($_id: Int!, $session: String!) {
    deleteRegistryForm(_id: $_id, session: $session) {
      __typename
    }
  }
`;

export const toggleQuotaMutation = gql`
  mutation toggleQuota($_id: Int!, $session: String!) {
    toggleQuota(_id: $_id, session: $session) {
      __typename
    }
  }
`;

export const updateFieldsMutation = gql`
  mutation updateFields($fields: [FieldArray], $session: String!) {
    updateFields(fields: $fields, session: $session) {
      __typename
    }
  }
`;

export const createAnnouncement = gql`
  mutation createAnnouncement(
    $title: String!
    $description: String!
    $thumbnail: String!
    $session: String!
  ) {
    createAnnouncement(
      title: $title
      description: $description
      thumbnail: $thumbnail
      session: $session
    ) {
      _id
    }
  }
`;

export const deleteAnnouncement = gql`
  mutation deleteAnnouncement($_id: String!, $session: String!) {
    deleteAnnouncement(_id: $_id, session: $session) {
      _id
    }
  }
`;
