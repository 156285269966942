import React, { useEffect, useRef, useState } from "react";
import { NavSecondary } from "../../components/NavSecondary";

import { Link } from "react-router-dom";
import {
  SmallGalleryImage,
  MobileSmallGalleryImage,
} from "./components/gallerySmallImage";

import { ScreenSize } from "../../tools/dimensions.js";

import "./styles.home.css";
import "./styles.home.content.css";
import {
  ArrowRightIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  CloseIcon,
  MailOutlineIcon,
  MarkerOutlineIcon,
  PhoneOutlineIcon,
} from "../../assets/icons";
import { ReviewCard } from "./components/reviewCard";
import { Map } from "./components/map";
import { useQuery } from "@apollo/client";
import { getAnnouncements } from "../../graphql/queries";
import { toUrlSafe } from "../../tools/urlSafeFormat";

import popupimageLandscape from "../../assets/popupinfo-landscape.webp";
import popupimagePortrait from "../../assets/popupinfo-portrait.webp";

import popupimageAdvert from "../../assets/popup-reklam.webp";

import popupimageAtaturk from "../../assets/popup-ataturkegitimkampusu.webp";

function Header() {
  return (
    <header id="page-main" className="landingHeader">
      <div className="headerWrap">
        <h1 id="action-title_1">
          Çocukların Hayalleri Kadar Renkli Bir Kreş, Kampüs Kreş
        </h1>
        <Link
          id="action-button_name_1"
          to="/okulumuz/hakkimizda"
          className="button bold whiteBack blueFront"
        >
          Hakkımızda Bilgi Edinin
        </Link>
      </div>
      <NavSecondary />
    </header>
  );
}

function Differance() {
  return (
    <div id="page-differances" className="homeContentBox white">
      <h2
        id="differances-section_title_1"
        className="homeContentBoxTitle brand"
      >
        Farkımız
      </h2>
      <div className="differanceWrap">
        <div className="differanceBox">
          <h3 id="differances-card_title_1">Çiftliğimiz</h3>
          <p id="differances-card_description_1">
            Çocuklarımızı büyütürken hayvan dostlarımızla tanışmalarını ve
            onlarla vakit geçirmelerini sağlıyoruz.
          </p>
        </div>
        <div className="differanceBox">
          <h3 id="differances-card_title_2">Yaz Okulumuz</h3>
          <p id="differances-card_description_2">
            Çocuklarımıza kendi bahçemizde yazın havuz ve bahçe keyfi
            yaşatıyoruz.
          </p>
        </div>
        <div className="differanceBox">
          <h3 id="differances-card_title_3">
            Bebeklerimizi Sevgi ile Büyütüyoruz
          </h3>
          <p id="differances-card_description_3">
            Ailelerin gözleri arkada kalmadan bebeklerinize en iyi şekilde
            bakıyoruz.
          </p>
        </div>
        <div className="differanceBox">
          <h3 id="differances-card_title_4">Etkinliklerimiz</h3>
          <p id="differances-card_description_4">
            Eğlenmeden oğrenilmez, çocuklarımızı mutlu büyütüyoruz okulu
            sevdiriyoruz.
          </p>
        </div>
        <div className="differanceBox">
          <h3 id="differances-card_title_5">Okul İçinde Doğa</h3>
          <p id="differances-card_description_5">
            Okulumuzda çocuklarımızla 4 mevsimin tadını 3.970 m2 açık alanımızda
            çıkarıyoruz.
          </p>
        </div>
        <div className="differanceBox">
          <h3 id="differances-card_title_6">Binamız</h3>
          <p id="differances-card_description_6">
            5.000 m2 kapalı kullanım alanına sahip kreşimiz, içerisinde doğal ve
            taze havayla yaşanacak şekilde tasarlandı.
          </p>
        </div>
        <div className="differanceBox">
          <h3 id="differances-card_title_7">Her Çocuğa Bir Ağaç</h3>
          <p id="differances-card_description_7">
            Her öğrencimize okul ormanımızda kendine ait bir ağaç yetiştirme
            fırsatı tanıyoruz.
          </p>
        </div>
        <div className="differanceBox">
          <h3 id="differances-card_title_8">Okul Sonrası Yaşam Devam Ediyor</h3>
          <p id="differances-card_description_8">
            Öğrencilerimiz okul sonrası shuttlelar aracılığıyla WellClub spor
            tesislerinden faydalanabilmekte.
          </p>
        </div>
      </div>
    </div>
  );
}

// eslint-disable-next-line
function Gallery({ winWidth }) {
  let imageStock = [
    {
      image: require("../../assets/compressed/352.webp"),
      displayName: "Bahçemizde Mutlu Zamanlar",
    },
    {
      image: require("../../assets/compressed/232.webp"),
      displayName: "Penguen Kostümlerimiz",
    },
    {
      image: require("../../assets/compressed/330.webp"),
      displayName: "Bu fotoğrafa bir başlık seçilmedi.",
    },
    {
      image: require("../../assets/compressed/342.webp"),
      displayName: "Bu fotoğrafa bir başlık seçilmedi.",
    },
    {
      image: require("../../assets/compressed/344.webp"),
      displayName: "Bu fotoğrafa bir başlık seçilmedi.",
    },
    {
      image: require("../../assets/compressed/346.webp"),
      displayName: "Bu fotoğrafa bir başlık seçilmedi.",
    },
    {
      image: require("../../assets/compressed/351.webp"),
      displayName: "Bu fotoğrafa bir başlık seçilmedi.",
    },
    {
      image: require("../../assets/compressed/371.webp"),
      displayName: "Bu fotoğrafa bir başlık seçilmedi.",
    },
    {
      image: require("../../assets/compressed/45.webp"),
      displayName: "Bu fotoğrafa bir başlık seçilmedi.",
    },
    {
      image: require("../../assets/compressed/TKM_3909.webp"),
      displayName: "Bu fotoğrafa bir başlık seçilmedi.",
    },
    {
      image: require("../../assets/compressed/115.webp"),
      displayName: "Bu fotoğrafa bir başlık seçilmedi.",
    },
    {
      image: require("../../assets/compressed/464.webp"),
      displayName: "Bu fotoğrafa bir başlık seçilmedi.",
    },
  ];

  const [selectedImage, setSelectedImage] = useState(imageStock[1]);

  const [slideRow, setSlideRow] = useState(0);

  useEffect(() => {
    setSlideRow(1);
  }, []);

  useEffect(() => {
    if (winWidth < 1115) {
      const sliderWrap = document.querySelector(".galleryMobileSlider");
      const slides = document.querySelector(".galleryMobileSlides");
      const slideChilds = slides.childNodes;

      const currentSlide = slideChilds[slideRow];

      currentSlide.classList.add("onDisplay");
      let nextSibling = currentSlide.nextSibling;
      nextSibling?.classList.add("next");
      let previousSibling = currentSlide.previousSibling;
      previousSibling?.classList.add("prev");

      let slideRightButton = document.querySelector(
        "#gallery-slidecontrol-right"
      );
      if (!nextSibling) {
        slideRightButton.style.display = "none";
      } else if (slideRightButton.style.display === "none") {
        slideRightButton.style.display = "flex";
      }

      let slideLeftButton = document.querySelector(
        "#gallery-slidecontrol-left"
      );
      if (!previousSibling) {
        slideLeftButton.style.display = "none";
      } else if (slideLeftButton.style.display === "none") {
        slideLeftButton.style.display = "flex";
      }

      if (nextSibling?.classList.value.includes("onDisplay")) {
        nextSibling.classList.remove("onDisplay");
      }

      if (previousSibling?.classList.value.includes("onDisplay")) {
        previousSibling.classList.remove("onDisplay");
      }

      if (currentSlide?.classList.value.includes("prev")) {
        currentSlide.classList.remove("prev");
      }

      if (currentSlide?.classList.value.includes("next")) {
        currentSlide.classList.remove("next");
      }

      setSelectedImage(imageStock[slideRow]);

      slides.style.left = `${
        (sliderWrap.offsetWidth - currentSlide.offsetWidth) / 2 -
        slideRow *
          (currentSlide.offsetWidth +
            parseInt(getComputedStyle(slides).gap.replace(/[a-z]*/gi, "")))
      }px`;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [slideRow, winWidth]);

  let displayImageLinear =
    "linear-gradient(31.36deg, rgba(0, 0, 0, 0.6) 22.91%, rgba(0, 0, 0, 0) 50.17%)";

  return (
    <div className="homeContentBox blue">
      <h2 className="homeContentBoxTitle white">Kreşten kareler</h2>
      {winWidth > 1115 ? (
        <div className="galleryContentWrap">
          <div
            style={{
              backgroundImage: `${displayImageLinear}, url(${selectedImage.image})`,
            }}
            className="galleryDisplayImage"
          >
            {selectedImage.displayName}
          </div>
          <div className="galleryImageGridAndButtonWrap">
            <div className="galleryImageGrid">
              <SmallGalleryImage
                onClick={() =>
                  setSelectedImage({
                    displayName: imageStock[0].displayName,
                    image: imageStock[0].image,
                  })
                }
                selectedImage={selectedImage}
                image={imageStock[0].image}
              />
              <SmallGalleryImage
                onClick={() =>
                  setSelectedImage({
                    displayName: imageStock[1].displayName,
                    image: imageStock[1].image,
                  })
                }
                selectedImage={selectedImage}
                image={imageStock[1].image}
              />
              <SmallGalleryImage
                onClick={() =>
                  setSelectedImage({
                    displayName: imageStock[2].displayName,
                    image: imageStock[2].image,
                  })
                }
                selectedImage={selectedImage}
                image={imageStock[2].image}
              />
              <SmallGalleryImage
                onClick={() =>
                  setSelectedImage({
                    displayName: imageStock[3].displayName,
                    image: imageStock[3].image,
                  })
                }
                selectedImage={selectedImage}
                image={imageStock[3].image}
              />
              <SmallGalleryImage
                onClick={() =>
                  setSelectedImage({
                    displayName: imageStock[4].displayName,
                    image: imageStock[4].image,
                  })
                }
                selectedImage={selectedImage}
                image={imageStock[4].image}
              />
              <SmallGalleryImage
                onClick={() =>
                  setSelectedImage({
                    displayName: imageStock[5].displayName,
                    image: imageStock[5].image,
                  })
                }
                selectedImage={selectedImage}
                image={imageStock[5].image}
              />
              <SmallGalleryImage
                onClick={() =>
                  setSelectedImage({
                    displayName: imageStock[6].displayName,
                    image: imageStock[6].image,
                  })
                }
                selectedImage={selectedImage}
                image={imageStock[6].image}
              />
              <SmallGalleryImage
                onClick={() =>
                  setSelectedImage({
                    displayName: imageStock[7].displayName,
                    image: imageStock[7].image,
                  })
                }
                selectedImage={selectedImage}
                image={imageStock[7].image}
              />
              <SmallGalleryImage
                onClick={() =>
                  setSelectedImage({
                    displayName: imageStock[8].displayName,
                    image: imageStock[8].image,
                  })
                }
                selectedImage={selectedImage}
                image={imageStock[8].image}
              />
              <SmallGalleryImage
                onClick={() =>
                  setSelectedImage({
                    displayName: imageStock[9].displayName,
                    image: imageStock[9].image,
                  })
                }
                selectedImage={selectedImage}
                image={imageStock[9].image}
              />
              <SmallGalleryImage
                onClick={() =>
                  setSelectedImage({
                    displayName: imageStock[10].displayName,
                    image: imageStock[10].image,
                  })
                }
                selectedImage={selectedImage}
                image={imageStock[10].image}
              />
              <SmallGalleryImage
                onClick={() =>
                  setSelectedImage({
                    displayName: imageStock[11].displayName,
                    image: imageStock[11].image,
                  })
                }
                selectedImage={selectedImage}
                image={imageStock[11].image}
              />
            </div>
            <Link to="/galeri" className="button whiteBack blueFront but-4324">
              Tüm Fotoğrafları Görüntüle
            </Link>
          </div>
        </div>
      ) : (
        <div className="galleryContentWrap">
          <div
            style={{
              backgroundImage: `${displayImageLinear}, url(${selectedImage.image})`,
            }}
            className="galleryDisplayImage"
          >
            {selectedImage.displayName}
          </div>
          <div className="galleryMobileSlider">
            <span
              onClick={() => setSlideRow((oldRow) => oldRow - 1)}
              id="gallery-slidecontrol-left"
            >
              <ChevronLeftIcon />
            </span>
            <span
              onClick={() => setSlideRow((oldRow) => oldRow + 1)}
              id="gallery-slidecontrol-right"
            >
              <ChevronRightIcon />
            </span>
            <div className="galleryMobileSlides">
              <MobileSmallGalleryImage
                selectedImage={selectedImage}
                image={imageStock[0].image}
              />
              <MobileSmallGalleryImage
                selectedImage={selectedImage}
                image={imageStock[1].image}
              />
              <MobileSmallGalleryImage
                selectedImage={selectedImage}
                image={imageStock[2].image}
              />
              <MobileSmallGalleryImage
                selectedImage={selectedImage}
                image={imageStock[3].image}
              />
              <MobileSmallGalleryImage
                selectedImage={selectedImage}
                image={imageStock[4].image}
              />
              <MobileSmallGalleryImage
                selectedImage={selectedImage}
                image={imageStock[5].image}
              />
              <MobileSmallGalleryImage
                selectedImage={selectedImage}
                image={imageStock[6].image}
              />
              <MobileSmallGalleryImage
                selectedImage={selectedImage}
                image={imageStock[7].image}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

function Reviews({ winWidth }) {
  const [slideRow, setSlideRow] = useState(0);

  useEffect(() => {
    setSlideRow(1);
  }, []);

  useEffect(() => {
    const sliderWrap = document.querySelector(".reviewsSlider");
    const slides = document.querySelector(".reviewsSlides");
    const slideChilds = slides.childNodes;

    const currentSlide = slideChilds[slideRow];

    currentSlide.classList.add("onDisplay");
    let nextSibling = currentSlide.nextSibling;
    nextSibling?.classList.add("next");
    let previousSibling = currentSlide.previousSibling;
    previousSibling?.classList.add("prev");

    let slideRightButton = document.querySelector(
      "#reviews-slidecontrol-right"
    );
    if (!nextSibling) {
      slideRightButton.style.display = "none";
    } else if (slideRightButton.style.display === "none") {
      slideRightButton.style.display = "flex";
    }

    let slideLeftButton = document.querySelector("#reviews-slidecontrol-left");
    if (!previousSibling) {
      slideLeftButton.style.display = "none";
    } else if (slideLeftButton.style.display === "none") {
      slideLeftButton.style.display = "flex";
    }

    if (nextSibling?.classList.value.includes("onDisplay")) {
      nextSibling.classList.remove("onDisplay");
    }

    if (previousSibling?.classList.value.includes("onDisplay")) {
      previousSibling.classList.remove("onDisplay");
    }

    if (currentSlide?.classList.value.includes("prev")) {
      currentSlide.classList.remove("prev");
    }

    if (currentSlide?.classList.value.includes("next")) {
      currentSlide.classList.remove("next");
    }

    slides.style.left = `${
      (sliderWrap.offsetWidth - currentSlide.offsetWidth) / 2 -
      slideRow *
        (currentSlide.offsetWidth +
          parseInt(getComputedStyle(slides).gap.replace(/[a-z]*/gi, "")))
    }px`;
  }, [slideRow, winWidth]);

  const ErkekAvatar = require("../../assets/man.png");
  const KizAvatar = require("../../assets/girl.png");

  return (
    <div id="page-reviews" className="homeContentBox brand">
      <h2 id="reviews-section_title_1" className="homeContentBoxTitle white">
        Mutlu velilerimiz
      </h2>
      <div className="reviewsSlider">
        <span
          onClick={() => setSlideRow((oldRow) => oldRow - 1)}
          id="reviews-slidecontrol-left"
        >
          <ChevronLeftIcon />
        </span>
        <span
          onClick={() => setSlideRow((oldRow) => oldRow + 1)}
          id="reviews-slidecontrol-right"
        >
          <ChevronRightIcon />
        </span>
        <div className="reviewsSlides">
          <ReviewCard avatar={KizAvatar} name="Pınar Dursun" date="2018">
            Ev sıcaklığında bir kreş. Her gün koşa koşa geldiği ve gitmek
            istemediği ikinci evi kızımızın. Biliyoruz ki burada kendini fark
            edecek ve en büyük önceliği kaliteli öğrenmek. Kampüslü olmak
            ayrıcalık ve daha 5 yaşındayken profesyonellikle tanışmaktır.
            Herkese teşekkürler.
          </ReviewCard>
          <ReviewCard avatar={KizAvatar} name="İlke Öndül" date="2018">
            Çocuğumdaki potansiyeli her alanda keşfedip açığa çıkardığınız ve
            daha da geliştirip, arttırdığınız için ve bunu alanında en iyi
            eğitimciler eşliğinde yaptığınız için sizlere minnettarız.
          </ReviewCard>
          <ReviewCard avatar={KizAvatar} name="Özlem Özcan Akat" date="2018">
            Kampüs Kreş gibi okulları olan bir millerin istikbali emniyettedir.
            Her şey ama her şey için çok teşekkürler Kampüs Kreş Ailesi.
          </ReviewCard>
          <ReviewCard avatar={KizAvatar} name="Neylan Pek" date="2018">
            Kampüs Kreş yönetimi olarak çok kurumsal ve profesyonel olduğunuzu
            düşünüyorum. Çocuğumu gönül rahatlığıyla gönderdiğimi
            söyleyebilirim. Öğretmenlerin yaklaşımı ve ilgisinin de gayet
            yerinde olduğunu gözlemliyorum. Teşekkür ederim böyle bir kurumu
            ülkemizde var ettiğiniz için.
          </ReviewCard>
          <ReviewCard avatar={KizAvatar} name="Güldeste Varol" date="2018">
            Kampüs Kreş, eğitimi ile, öğretimi ile çocuklarımıza sunduğu
            olanaklar ile Türkiye'nin en iyi kreşi. Çocuklarımıza çocukluğunu
            doyasıya yaşatarak, eğlenerek öğrenme olanağı sunuyor. Kızım burada
            çok mutlu. Kızım burada çocukluğunu yaşayabiliyor. Emeği geçenlere
            sonsuz teşekkürler.
          </ReviewCard>
          <ReviewCard avatar={KizAvatar} name="Semra Özgür" date="2018">
            Oğlumdaki olumlu yöndeki değişimi ben dahil tüm çevremiz fark etmiş
            durumda. Kampüs Kreş ile birlikte "Okul çok iyi geldi." cümlesini
            sıkça kullanıyor ve duyuyoruz. Her şeyden öte büyük istekle ve
            mutlulukla okula gidiyor.
          </ReviewCard>
          <ReviewCard avatar={KizAvatar} name="Begüm Gözde Ertaş" date="2018">
            Eylül ayında başladığı ilk gün okula ağlayarak giden oğlum hasta
            olsa bile okula gitmek için sabırsızlanıyor. 6 ayda gerek sosyal
            gerek dil gelişimi, kas gelişiminde son derece gelişim gösterdi.
            Kampüs Kreş verdiğimiz en doğru kararlardan birisidir. Sınıf
            öğretmenlerimizin emeğine sağlık. Çok teşekkürler. İyi ki varsınız.
          </ReviewCard>
          <ReviewCard avatar={KizAvatar} name="Beyhan Özalp" date="2018">
            Kampüs Kreş'i çok beğeniyorum. Keşke bende çocuk olsaydım dedirten
            bir okul. Okulun kalitesi her alanında belli oluyor. Sınıfımızdan ve
            öğretmenlerimizden çok memnunuz. İlgilerinden ötürü teşekkür ederiz.
          </ReviewCard>
          <ReviewCard avatar={ErkekAvatar} name="Selamet Yılmaz" date="2018">
            Kampüs Kreş olarak son derece çağdaş bir ortamda, etkin ve güler
            yüzlü personel ile beraber olmaktan memnuniyet duyuyoruz. Gönül
            rahatlığı ile çocuğumuzu teslim ediyoruz ve gelişimini izliyoruz.
            Başarılarınızın devamını diliyoruz.
          </ReviewCard>
          <ReviewCard avatar={KizAvatar} name="Dilşad Bingöl" date="2017">
            Öncelikle okulumuzun mimarı yapısını ve donanımını çocuğumun
            gelişimi için yeterli buluyorum. Öğretmenlerimizin çocuğumuza ve
            bize karşı göstermiş oldukları iletişim dilini beğeniyorum. Daima
            bizimle işbirliği içinde ve destekleyiciler. Çocuğumuza karşı
            şefkatli ve özveriler.
          </ReviewCard>
          <ReviewCard avatar={ErkekAvatar} name="Gökhan Yıldız" date="2017">
            Çocuğumu gönül rahatlığı ile emanet ettiğim donanım ve fiziki
            imkanlarını çok beğendiğim bir okul. Tüm personelin görevini
            titizlikle yaptığı ve mesleklerinin erbabı kişiler olduğu her
            hallerinden belli.
          </ReviewCard>
          <ReviewCard avatar={KizAvatar} name="Özlem Özcan Akat" date="2017">
            Çocuğumu güvenle emanet edebildiğim, çocuğuma anne şefkatiyle
            yaklaşan öğretmenlerin bir arada bulunduğu, bir çocuğun
            isteyebileceği tüm şeyleri bünyesinde barındıran, her geldiğimde
            keşke çocuk olsam dediğim bir okul Kampüs Kreş. Emeği geçen herkse
            sonsuz teşekkür.
          </ReviewCard>
          <div className="reviewCard reviewAdvert">
            <div className="avatarGroup">
              <div className="advertSmallAvatar">
                <img alt="kiz avatar" src={KizAvatar} />
              </div>
              <div className="advertSmallAvatar">
                <img alt="erkek avatar" src={ErkekAvatar} />
              </div>
            </div>
            <h3>Tüm Veli Görüşlerini Görüntüleyin</h3>
            <a href="/okulumuz/veli-gorusleri">Veli Görüşleri</a>
          </div>
        </div>
      </div>
    </div>
  );
}

function TimeMap() {
  var date = new Date().toLocaleDateString();
  date = {
    month: parseInt(
      date.split(".").length !== 1 ? date.split(".")[1] : date.split("/")[1]
    ),
    year: parseInt(
      date.split(".").length !== 1 ? date.split(".")[2] : date.split("/")[2]
    ),
  };
  let month = date.month;
  return (
    <div id="page-timemap" className="homeContentBox blue">
      <h2 id="timemap-section_title_1" className="homeContentBoxTitle white">
        Şimdiye Kadar
      </h2>
      <div className="timeMapWrap">
        <div className="timeWrapBox oyunoynadik">
          <p>
            {(date.year - 2013) * ((12 - month) * (5 * 4) + 12 * (5 * 4)) * 9}{" "}
            Saat
          </p>
          <h3>Oyun Oynadık</h3>
        </div>
        <div className="timeWrapBox hayvanbesledik">
          <p>
            {(date.year - 2013) * ((12 - month) * (5 * 4) + 12 * (5 * 3)) * 9}{" "}
            Saat
          </p>
          <h3>Hayvanları Besledik</h3>
        </div>
        <div className="timeWrapBox dramayaptik">
          <p>
            {(date.year - 2013) * ((12 - month) * (1 * 4) + 12 * (1 * 4)) * 10}{" "}
            Saat
          </p>
          <h3>Drama Yaptık</h3>
        </div>
        <div className="timeWrapBox ingilizcekonustuk">
          <p>
            {(date.year - 2015) * ((12 - month) * (5 * 4) + 12 * (5 * 4)) * 8}{" "}
            Saat
          </p>
          <h3>İngilizce Konuştuk</h3>
        </div>
        <div className="timeWrapBox yuzduk">
          <p>
            {(date.year - 2015) *
              ((6 < month && month < 9 ? (12 - month) * (7 * 3) : 0) +
                2 * (7 * 3)) *
              3}{" "}
            Saat
          </p>
          <h3>Yüzdük</h3>
        </div>
      </div>
    </div>
  );
}

function Contact() {
  useEffect(() => {
    if (window.location.hash === "#contact")
      document.querySelector("#contact").scrollIntoView();
  });
  return (
    <div id="contact" className="homeContentBox purple">
      <h2 id="contact-section_title_1" className="homeContentBoxTitle white">
        Bize ulaşın
      </h2>
      <div className="commWrap">
        <Map />
        <div className="commBox">
          <a className="commMethodWrap" href="mailto:info@kampuskres.com.tr">
            <div>
              <MailOutlineIcon />
            </div>
            <p id="contact-method_1">info@kampuskres.com.tr</p>
          </a>
          <div className="commMethodWrap">
            <div>
              <PhoneOutlineIcon />
            </div>
            <p id="contact-method_2">+90 216 420 75 75</p>
          </div>
          <div className="commMethodWrap">
            <div>
              <MarkerOutlineIcon />
            </div>
            <p id="contact-method_3">
              Dudullu Organize Sanayi Bölgesi, 5. Cadde, No:6 Ümraniye /
              İSTANBUL
            </p>
          </div>
        </div>
        <a
          id="contact-studentApplication_button_1"
          href="/ogrencibasvuru?v=2"
          className="commButton"
        >
          Öğrenci Başvuru Formu
          <ArrowRightIcon />
        </a>
      </div>
    </div>
  );
}

//function Credit() {
//return (
//<div className="homeContentBox white">
//<h2 className="homeContentBoxTitle purple">
//sitemiz liseliler tarafından yapıldı
//</h2>
//<div className="creditArea">
//<div className="credit">
//<h3>Naim Yolaçan</h3>
//<span>Tasarım</span>
//</div>
//<a
//  href="https://www.linkedin.com/in/alp-durak-44303b213/"
//className="credit"
//        >
//         <h3>Alp Durak</h3>
//       <span>Yazılım & Tasarım</span>
//   </a>
// <div className="credit">
//          <h3>Yağmur Özdemir</h3>
//        <span>Tasarım</span>
//    </div>
//</div>
//    </div>
//);
//}

function HomeContent({ winWidth }) {
  return (
    <div id="page-action" className="homeContentColumn">
      <Differance />
      <Reviews winWidth={winWidth} />
      <TimeMap />
      <Contact />
    </div>
  );
}

function PopUp({ winWidth }) {
  const [on, modalState] = useState(true);

  let toggle = function () {
    modalState((oldState) => !oldState);
  };

  let {
    data: announcements,
    loading,
    error,
  } = useQuery(getAnnouncements, {
    variables: {
      skip: 0,
      limit: 1,
    },
  });

  try {
    if (loading) return <div style={{ display: "none" }}>loading pop up</div>;

    if (error) {
      return <div style={{ display: "none" }}>error while loading pop up</div>;
    }

    announcements = announcements.getAnnouncements.announcements;

    let lastAnnouncement = announcements[0];

    return (
      on && (
        <div id="page-announcement_popup" className="homePopUp">
          <i onClick={() => toggle()}>
            <CloseIcon />
          </i>
          {winWidth <= 1000 && (
            <a href="/okulumuz/duyurular" className="coverbutton">
              button cover
            </a>
          )}
          <img
            src={lastAnnouncement.thumbnail}
            alt={toUrlSafe(lastAnnouncement.title)}
          />
          <div className="mobileDetails">
            <span>{lastAnnouncement.title}</span>
          </div>
          <span>{lastAnnouncement.title}</span>
          <a id="announcement_popup-button_name_1" href="/okulumuz/duyurular">
            Duyuruyu Görüntüle
          </a>
        </div>
      )
    );
  } catch {
    return <div style={{ display: "none" }}>error while loading pop up</div>;
  }
}

// function AdSpace({ path, href }) {
//   return (
//     <div className="adSpace">
//       {/* {href ? (
//         <a href={href}>
//           <img src={path} alt="ad" />
//         </a>
//       ) : (
//         <img src={path} alt="ad" />
//       )} */}
//     </div>
//   );
// }

export function Home() {
  const { winWidth } = ScreenSize();
  // const dialog = useRef(null);

  return (
    <React.Fragment>
      {/* <dialog
        ref={dialog}
        onLoad={() => dialog.current.showModal()}
        className="infoPopUp asp_1-1"
      >
        <form onSubmit={() => dialog.current.close()} method="dialog">
          <button type="submit" autoFocus>
            <CloseIcon />
          </button>
        </form>
        <a href="https://bit.ly/4alWKqg">
          Comment: https://bit.ly/4alWKqg
          <picture>
            Comment: 
            <source media="(max-width: 620px)" srcSet={popupimagePortrait} />
            <source media="(min-width: 621px)" srcSet={popupimageLandscape} />
            <img src={popupimageLandscape} alt="Yorumlarınızı bekliyoruz" />
            <img src={popupimageAdvert} alt="Bizimle çalışmak ister misin?" />

            <img
              src={popupimageAtaturk}
              alt="Dudullu OSB Atatürk Eğitim Kampüsü"
            />
          </picture>
        </a>
      </dialog> */}
      <PopUp winWidth={winWidth} />
      <Header />
      <HomeContent winWidth={winWidth} />
    </React.Fragment>
  );
}
