import React from "react";
import { Link } from "react-router-dom";
import {
  DocumentIcon,
  DocumentsIcon,
  EducationIcon,
  ImageIcon,
  NewspaperIcon,
  PhoneIcon,
  ToycarIcon,
} from "../../assets/icons";

import "./styles.nav.secondary.css";

export function NavSecondary() {
  return (
    <div className="secondaryNav">
      <nav>
        <Link to="/okulumuz" className="navigationButton blue">
          <EducationIcon />
          Okulumuz
        </Link>
        <Link to="/hizmetlerimiz" className="navigationButton orange">
          <ToycarIcon />
          Hizmetlerimiz
        </Link>
        <Link to="/galeri" className="navigationButton green">
          <ImageIcon />
          Galeri
        </Link>
        <a href="/makaleler" className="navigationButton magenta">
          <DocumentsIcon />
          Makaleler
        </a>
        <Link to="/basinda-biz" className="navigationButton purple">
          <NewspaperIcon />
          Basında Biz
        </Link>
        <a href="/#contact" className="navigationButton pink">
          <PhoneIcon />
          İletişim
        </a>
        <Link to="/ogrencibasvuru?v=2" className="navigationButton white pulse">
          <DocumentIcon />
          Öğrenci Başvuru Formu
        </Link>
      </nav>
    </div>
  );
}
