import React from "react";

import "./styles.askinput.css";

export function AskInput({ id, label, buttons, value, ...props }) {
  function getValue() {
    if (typeof value.get === "function") {
      return value.get();
    } else {
      return value.get;
    }
  }

  return (
    <div {...props} className="input_ask">
      <p>{label}</p>
      <div className="ask_radios">
        <input
          defaultChecked={getValue() === "y"}
          id={`${id}_y`}
          type="radio"
          className="languageRadio"
          name={`${id}_askSelect`}
          value="y"
          onClick={(event) => {
            value.set(event.currentTarget.value);
          }}
          onChange={(event) => {
            if (event.target.checked) {
              value.set(event.currentTarget.value);
            }
          }}
        />
        <div data-forvalue="y" className="askRadioSelect">
          <label htmlFor={`${id}_y`}>{buttons.first}</label>
        </div>
        <input
          defaultChecked={getValue() === "n"}
          id={`${id}_n`}
          type="radio"
          className="askRadio"
          name={`${id}_askSelect`}
          value="n"
          onClick={(event) => {
            value.set(event.currentTarget.value);
          }}
          onChange={(event) => {
            if (event.target.checked) {
              value.set(event.currentTarget.value);
            }
          }}
        />
        <div data-forvalue="n" className="askRadioSelect">
          <label htmlFor={`${id}_n`}>{buttons.second}</label>
        </div>
      </div>
    </div>
  );
}
