import React from "react";

import "./styles.selectinput.css";

export function SelectInput({ id, label, value, options, ...props }) {
  function getValue() {
    if (typeof value.get === "function") {
      return value.get();
    } else {
      return value.get;
    }
  }

  return (
    <div {...props} className="input_select">
      <p>{label}</p>
      <div className="select">
        <select
          id={id}
          defaultValue={getValue() || options[0].value}
          value={getValue()}
          onChange={(event) => {
            value.set(parseInt(event.target.value));
            if (props.onChange) {
              props.onChange(event);
            }
          }}
        >
          {options.map((option, i) => (
            <option key={i} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
}
