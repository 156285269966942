import { phoneNumberRegex } from "../regex";
import { TextInput } from "./textInput";

// eslint-disable-next-line no-extend-native
String.prototype.replaceAtCallback = function (index, char) {
  let a = this.split("");
  a[index] = char(a[index]);
  return a.join("");
};
// eslint-disable-next-line no-extend-native
String.prototype.replaceAt = function (index, char) {
  let a = this.split("");
  a[index] = char;
  return a.join("");
};

function handlePhoneNumberChange(value) {
  let phoneNumber = value;

  // remove characters that arent digits and parantheses
  phoneNumber = phoneNumber.replace(/((?!\(|\)|\d).)/g, "");

  let valueCharArray = phoneNumber.split("");

  // remove unwanted parantheses
  for (let i = 0; i < valueCharArray.length; i++) {
    if (valueCharArray[i] === "(" && i !== 1) {
      phoneNumber = phoneNumber.replaceAt(i, "");
      // update char array
      valueCharArray = phoneNumber.split("");
    }
    if (valueCharArray[1] === "(" && valueCharArray[i] === ")" && i !== 5) {
      phoneNumber = phoneNumber.replaceAt(i, "");
      // update char array
      valueCharArray = phoneNumber.split("");
    }
  }

  // remove both parantheses if one of them is removed
  if (!valueCharArray.includes(")") && valueCharArray.includes("(")) {
    return (phoneNumber = phoneNumber.replace("(", ""));
  }
  if (valueCharArray.includes(")") && !valueCharArray.includes("(")) {
    return (phoneNumber = phoneNumber.replace(")", ""));
  }

  // add parantheses
  if (!valueCharArray.includes(")")) {
    if (phoneNumber.length >= 4 && !valueCharArray.includes("(")) {
      let valueWithGuides = phoneNumber.replaceAtCallback(0, (matched) => {
        return `${matched}{$}`;
      });
      let substring = valueWithGuides.substring(1, 7);
      let prettified = valueWithGuides.replace(substring, (matched) => {
        return `(${matched.replace("{$}", "")})`;
      });

      phoneNumber = prettified;
      valueCharArray = phoneNumber.split("");
      return (phoneNumber = prettified);
    }
  }

  if (valueCharArray.includes("(") && valueCharArray.includes(")")) {
    // first space
    if (valueCharArray[6] !== " " && valueCharArray[6]) {
      phoneNumber = phoneNumber.replaceAtCallback(6, (matched) => {
        return ` ${matched}`;
      });
      valueCharArray = phoneNumber.split("");
    }

    // second space
    if (valueCharArray[10] !== " " && valueCharArray[10]) {
      phoneNumber = phoneNumber.replaceAtCallback(10, (matched) => {
        return ` ${matched}`;
      });
      valueCharArray = phoneNumber.split("");
    }

    // third space
    if (valueCharArray[13] !== " " && valueCharArray[13]) {
      phoneNumber = phoneNumber.replaceAtCallback(13, (matched) => {
        return ` ${matched}`;
      });
      valueCharArray = phoneNumber.split("");
    }
  }

  // change the last digit (15th) of the number with the last input
  if (valueCharArray.length > 16) phoneNumber = phoneNumber.replaceAt(15, "");

  return phoneNumber;
}

export function PhoneInput({ id, label, value, ...props }) {
  const formattedValue = {
    get: value.get,
    set: (newValue) => value.set(handlePhoneNumberChange(newValue)),
  };

  return (
    <TextInput
      {...props}
      id={id}
      type="tel"
      label={label}
      value={formattedValue}
      validate={(val) => !phoneNumberRegex.test(val)}
      pattern="[0][(][0-9]{3}[)] [0-9]{3} [0-9]{2} [0-9]{2}"
    />
  );
}
