import React from "react";

import "./styles.textinput.css";

export function TextInput({ id, type, label, value, validate, ...props }) {
  function getValue() {
    if (typeof value.get === "function") {
      return value.get();
    } else {
      return value.get;
    }
  }

  return (
    <div className="input_text">
      <input
        {...props}
        id={id}
        type={type}
        onChange={(e) => {
          const val = e.target.value;
          if (validate !== undefined) {
            if (validate(val)) {
              value.set(val);
            }
          } else {
            value.set(val);
          }
        }}
        value={getValue()}
        placeholder={label}
      />
      {getValue().length > 0 && <label htmlFor={id}>{label}</label>}
    </div>
  );
}
