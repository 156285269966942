import React from "react";

import "./styles.dateinput.css";

export function DateInput({ id, label, value, min, max, ...props }) {
  function getValue() {
    if (typeof value.get === "function") {
      return value.get();
    } else {
      return value.get;
    }
  }

  return (
    <div {...props} onChange={() => {}} className="input_date">
      <p>{label}</p>
      <div className="inputPlaceholder">
        <input
          id={id}
          type="date"
          min={min}
          max={max}
          onChange={(event) => {
            value.set(event.target.value);
            if (props.onChange) {
              props.onChange(event);
            }
          }}
          value={getValue()}
        />
      </div>
    </div>
  );
}
