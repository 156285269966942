import React from "react";
import { Helmet } from "react-helmet";

export function IYSMetni({ size }) {
  const doc3header = require("./assets/doc3header.png");

  return (
    <article className={`document${size ? ` ${size}` : ""}`}>
      <Helmet>
        <title>Kampüs Kreş - Ticari Elektronik İleti İzni</title>
        <meta name="title" content="Kampüs Kreş - Aydınlatma Metni" />
        <meta property="og:title" content="Kampüs Kreş - Aydınlatma Metni" />
        <meta name="twitter:title" content="Kampüs Kreş - Aydınlatma Metni" />
      </Helmet>
      <div id="scrolldummy"></div>
      <header>
        <img src={doc3header} alt="başlık"></img>
      </header>
      <div className="documentInner">
        <div className="documentColumn">
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <div>
            6563 sayılı Elektronik Ticaretin Düzenlenmesi Hakkında Kanun, Ticari
            İletişim ve Ticari Elektronik İletiler Hakkında Yönetmelik
            kapsamında <strong>KAMPÜS KREŞ (www.kampuskres.com.tr)</strong>{" "}
            (“Okul”) ile paylaştığım iletişim araçlarına, Okul ve İstanbul
            Dudullu OSB bünyesinde doğrudan ve dolaylı iştirakleri; Okul adına
            hizmet veren anlaşmalı üçüncü kişiler tarafından bana uygun ürün,
            uygulama, avantaj veya kampanyaların sunulması, ürün ve hizmetlerin
            tanıtılması ile pazarlama ve anket çalışmalarının yürütülmesi
            kapsamında her türlü ticari elektronik iletinin çağrı (arama), kısa
            mesaj (SMS/MMS), e-posta ve sosyal medya gibi kanallar aracılığı ile
            gönderilmesine onay veriyorum.
          </div>
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
        </div>
        <div className="documentColumn">
          <div>
            <strong>
              ALTAŞ OTO SANAYİ TİCARET ANONİM ŞİRKETİ (alsattakasla.com)
            </strong>
            <br />
            <strong>Adres:</strong> Cumhuriyet Mah. Mustafa Kemal Bul. No:102A
            Altınordu/ORDU
            <br />
            <strong>Telefon:</strong> 0 452 777 3 777 | 0850 307 9 307
            <br />
            <strong>E-Posta Adresi:</strong>{" "}
            <a href="mailto:info@alsattakasla.com">info@alsattakasla.com</a>
            <br />
            <strong>Web:</strong> www.alsattakasla.com
          </div>
        </div>
      </div>
    </article>
  );
}
